.skeleton-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; 
}

.skeleton-header {
  height: 40px; 
  width: 80%; 
  margin-bottom: 20px;
  background-color: rgba(128, 128, 128, 0.527); 
  border-radius: 4px;
  animation: waving 1.5s infinite ease-in-out; 
}

.skeleton-content {
  height: 20px;
  width: 80%; 
  margin-bottom: 10px;
  background-color: rgba(128, 128, 128, 0.527); 
  border-radius: 4px;
  animation: waving 1.5s infinite ease-in-out; 
}

@keyframes waving {
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.8);
  }
}