.about-section {
  display: flex;
  flex-direction: column;
  padding: 20px; /* Added padding */
  /* Gradient background */
  /* Added border radius for a softer look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow for depth */
}

.about-item {
  margin-bottom: 20px;
}

.about-title-head {
  font-size: 32px; /* Increased font size */
  font-weight: bold;
  color: #fff; /* Set text color to white for contrast */
  margin-bottom: 15px; /* Increased bottom margin */
}

.about-title {
  font-size: 24px;
  font-weight: bold;
  color: #fff; /* Set text color to white for contrast */
  margin-bottom: 10px;
}

.about-description {
  font-size: 16px;
  color: #fff; /* Set text color to a lighter shade for contrast */
  line-height: 1.6; /* Adjusted line height for better readability */
  padding-left: 20px; /* Added left padding */
}
